<template>
  <div>
    <v-app-bar app elevation="3">
      <v-row no-gutters>
        <v-col>
          <v-btn icon v-if="isLoggedIn" @click="drawer = !drawer">
            <v-icon color="black">mdi-menu</v-icon>
          </v-btn></v-col
        >

        <v-col>
          <div
            class="patra-logo-wrapper mx-auto"
            @click="$router.push({ path: '/' })"
          >
            <img src="@/assets/patra-logo.png" class="logo" />
          </div>
        </v-col>

        <v-col> </v-col>
      </v-row>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list class="mb-6">
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.path"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-btn class="ml-4" depressed @click="onLogout">
        <v-icon left>mdi-logout</v-icon>
        Αποσυνδεση
      </v-btn>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      drawer: false,
      items: [
        { title: "Αρχική", icon: "mdi-home", path: "/" },
        { title: "Χάρτης", icon: "mdi-map-marker", path: "/map" },
        { title: "Λίστα συναγερμών", icon: "mdi-view-list", path: "/list" },
      ],
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  methods: {
    ...mapActions(["logout"]),
    onLogout() {
      this.drawer = false;
      this.logout();
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-wrapper {
  height: 44px;
  width: 44px;
  overflow: hidden;
  border-radius: 50%;

  .logo {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.patra-logo-wrapper {
  height: auto;
  width: 135px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .logo {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>