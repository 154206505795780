<template>
  <div class="component-wrapper">
    <v-progress-circular
      indeterminate
      color="primary"
      v-if="alertsLoader"
      size="50"
      class="mx-auto mt-12"
    ></v-progress-circular>

    <v-btn
      class="mx-auto mt-2 mb-6"
      small
      depressed
      color="primary"
      @click="onGetAlerts"
      v-if="!alertsLoader"
    >
      <v-icon left>mdi-refresh</v-icon>
      Ανανεωση
    </v-btn>

    <v-simple-table
      v-if="$vuetify.breakpoint.smAndUp && alerts.length > 0 && !alertsLoader"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Id</th>
            <th class="text-left">Περιγραφή</th>
            <th class="text-left">Ώρα συναγερμού</th>
            <th class="text-left">Τύπος</th>
            <th class="text-left">Χρήστης</th>
            <th class="text-left">Ώρα ενέργειας</th>
            <th class="text-left">Ενέργειες</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(alert, index) in computedAlerts"
            :key="index"
            :class="{ 'red lighten-1 white--text': !alert.hasAnswered }"
          >
            <td>{{ alert.sensor.id }}</td>
            <td>{{ alert.sensor.locationDescription }}</td>
            <td>{{ moment(alert.time).format("DD/MM/YYYY HH:mm") }}</td>
            <td>{{ alert.sensor.type }}</td>
            <td>
              {{
                alert.firstAnswered
                  ? `${alert.firstAnswered.firstName} ${alert.firstAnswered.lastName}`
                  : "-"
              }}
            </td>
            <td>
              {{
                alert.firstAnswered
                  ? moment(alert.firstAnswered.actions.time).format(
                      "DD/MM/YYYY HH:mm"
                    )
                  : "-"
              }}
            </td>
            <td>
              <div class="d-flex">
                <v-btn
                  color="primary"
                  small
                  depressed
                  @click="onSeeMap(alert.sensor.id)"
                  class="mr-2"
                  >Δειτε στον χαρτη</v-btn
                >

                <v-btn
                  color="primary"
                  small
                  depressed
                  @click="onGetDirections(alert.sensor)"
                  class="mr-2"
                  >Οδηγίες</v-btn
                >

                <v-btn
                  class="mr-2"
                  depressed
                  color="success"
                  small
                  @click="
                    onRespondToAlert({ type: 'accept', alertId: alert.id })
                  "
                  :loading="loader.open && loader.type == 'accept'"
                  v-if="!alert.hasAnswered"
                  >Ενεργω</v-btn
                >

                <v-btn
                  depressed
                  color="grey"
                  class="white--text"
                  small
                  @click="
                    onRespondToAlert({ type: 'reject', alertId: alert.id })
                  "
                  :loading="loader.open && loader.type == 'reject'"
                  v-if="!alert.hasAnswered"
                  >Αγνοηση</v-btn
                >
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-expansion-panels
      v-if="$vuetify.breakpoint.xsOnly && alerts.length > 0 && !alertsLoader"
      v-model="activeNotif"
    >
      <v-expansion-panel v-for="(alert, index) in computedAlerts" :key="index">
        <v-expansion-panel-header
          :class="{ 'red lighten-1 white--text': !alert.hasAnswered }"
        >
          {{ alert.sensor.type }} ({{
            moment(alert.time).format("DD/MM/YYYY HH:mm")
          }})
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col class="d-flex align-center">Id</v-col>

            <v-col class="d-flex align-center">{{ alert.sensor.id }}</v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex align-center">Περιγραφή</v-col>

            <v-col class="d-flex align-center font-weight-bold">{{
              alert.sensor.locationDescription
            }}</v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex align-center">Ώρα συναγερμού</v-col>

            <v-col class="d-flex align-center">{{
              moment(alert.time).format("DD/MM/YYYY HH:mm")
            }}</v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex align-center">Τύπος</v-col>

            <v-col class="d-flex align-center">{{ alert.sensor.type }}</v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex align-center">Χρήστης</v-col>

            <v-col class="d-flex align-center">{{
              alert.firstAnswered
                ? `${alert.firstAnswered.firstName} ${alert.firstAnswered.lastName}`
                : "-"
            }}</v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex align-center">Ώρα ενέργειας</v-col>

            <v-col class="d-flex align-center">
              {{
                alert.firstAnswered
                  ? moment(alert.firstAnswered.actions.time).format(
                      "DD/MM/YYYY HH:mm"
                    )
                  : "-"
              }}</v-col
            >
          </v-row>

          <v-btn
            color="primary"
            class="mt-4 d-block mx-auto"
            small
            depressed
            @click="onSeeMap(alert.sensor.id)"
            >Δειτε στον χαρτη</v-btn
          >

          <v-btn
            color="primary"
            class="mt-2 d-block mx-auto"
            small
            depressed
            @click="onGetDirections(alert.sensor)"
            >Οδηγίες</v-btn
          >

          <template v-if="!alert.hasAnswered">
            <v-divider class="my-4"></v-divider>

            <div class="d-flex justify-center">
              <v-btn
                class="mr-2"
                depressed
                color="success"
                @click="onRespondToAlert({ type: 'accept', alertId: alert.id })"
                :loading="loader.open && loader.type == 'accept'"
                >Ενεργω</v-btn
              >

              <v-btn
                depressed
                color="error"
                @click="onRespondToAlert({ type: 'reject', alertId: alert.id })"
                :loading="loader.open && loader.type == 'reject'"
                >Αγνοηση</v-btn
              >
            </div>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      moment,
      activeNotif: [],
      loader: {
        open: false,
        type: null,
      },
      alertsLoader: false,
    };
  },
  created() {
    this.onGetAlerts();
  },
  computed: {
    ...mapState({
      userId: (state) => state.auth.userId,
      alerts: (state) => state.user.alerts,
    }),
    computedAlerts() {
      if (this.alerts.length == 0) {
        return [];
      }

      return this.alerts.map((alert) => {
        let hasAnswered = !!alert.users.find((user) => user.id == this.userId);
        let firstAnswered = alert.users.find(
          (user) => user.actions.type == "accept"
        ); //sorted by time in the server. TODO: move logic to server.

        return {
          ...alert,
          hasAnswered,
          firstAnswered,
        };
      });
    },
  },
  methods: {
    ...mapActions(["getAlerts", "respondToAlert"]),
    ...mapMutations(["setAlertSensor"]),
    onRespondToAlert(payload) {
      this.loader = {
        open: true,
        type: payload.type,
      };
      this.respondToAlert(payload)
        .then(() => {
          this.loader = {
            open: false,
            type: null,
          };
          this.onGetAlerts();
        })
        .catch(() => {
          this.loader = {
            open: false,
            type: null,
          };
        });
    },
    onGetAlerts() {
      this.alertsLoader = true;
      this.getAlerts().then(() => {
        this.alertsLoader = false;
      });
    },
    onSeeMap(sensorId) {
      this.setAlertSensor(sensorId);
      this.$router.push({ path: "/map" });
    },
    onGetDirections(sensor) {
      window.open(
        `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${sensor.latitude},${sensor.longitude}`
      );
    },
  },
  watch: {
    computedAlerts(newValue, oldValue) {
      if (newValue?.length > 0 && !newValue[0].hasAnswered) {
        this.activeNotif = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>