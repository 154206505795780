<template>
  <div class="component-wrapper align-center pt-8">
    <div class="title mb-8">Αρχική</div>

    <div
      class="primary white--text link pa-2 mb-2 text-center"
      @click="$router.push({ path: '/map' })"
    >
      Χάρτης
    </div>

    <div
      class="primary white--text link pa-2 text-center"
      @click="$router.push({ path: '/list' })"
    >
      Λίστα συναγερμών
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.link {
  width: 300px;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.85;
  }
}
</style>
