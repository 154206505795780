import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';

import Login from '@/views/Login'
import Home from '@/views/Home'
import Map from '@/views/Map'
import List from '@/views/List'

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location)
    .catch(err => {
      if (err.name != "NavigationDuplicated") {
        throw err;
      }
    })
};

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/map',
    component: Map,
    meta: { requiresAuth: true }
  },
  {
    path: '/list',
    component: List,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: "*",
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
    } else {
      next('login');
    }
  } else {
    next();
  }
})

export default router
