<template>
  <v-app>
    <audio ref="notif" src="notif.mp3" preload="auto"></audio>
    <Navigation />

    <v-main>
      <router-view></router-view>
    </v-main>

    <div class="px-5 py-1 d-flex align-center justify-center">
      <div class="body-2 mr-2">Powered by</div>
      <img class="footer-logo" src="@/assets/logo.png" />
    </div>

    <v-snackbar v-model="snackbar" color="gray darken-2" timeout="6000">
      <div class="d-flex align-center">
        <div class="body-1">Έχεις ένα συναγερμό</div>
        <v-btn small class="ml-auto" text @click="snackbar = !snackbar"
          >Ενταξει</v-btn
        >
      </div>
    </v-snackbar>
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation";
import { Plugins } from "@capacitor/core";
import { mapActions, mapState } from "vuex";
import firebase from "firebase/app";
import "firebase/messaging";

const { PushNotifications, Device, SplashScreen, Storage, App } = Plugins;

export default {
  components: {
    Navigation,
  },
  data() {
    return {
      snackbar: false,
      alertId: null,
    };
  },
  computed: {
    ...mapState({
      platform: (state) => state.platform,
    }),
  },
  async created() {
    const token = await Storage.get({ key: "token" });
    const userId = await Storage.get({ key: "userId" });

    if (token.value && userId.value) {
      await this.setToken({
        token: token.value,
        userId: userId.value,
      });

      this.getSensors();
      this.$router.push({ path: "/" });
    }

    await this.setPlatform();

    SplashScreen.hide();

    if (this.platform == "android") {
      PushNotifications.addListener(
        "pushNotificationReceived",
        (notification) => {
          if (this.$route.path == "/list") {
            this.getAlerts();
            this.snackbar = true;
          } else {
            this.$router.push({ path: "/list" });
            this.snackbar = true;
          }
        }
      );

      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification) => {
          if (this.$route.path == "/list") {
            this.getAlerts();
            this.snackbar = true;
          } else {
            this.$router.push({ path: "/list" });
            this.snackbar = true;
          }
        }
      );
    }

    if (this.platform == "web") {
      const firebaseConfig = {
        apiKey: "AIzaSyAnoAfiyiknnlQkTeQGeWAbwzyY2zdtcRA",
        authDomain: "freatia-b1958.firebaseapp.com",
        projectId: "freatia-b1958",
        storageBucket: "freatia-b1958.appspot.com",
        messagingSenderId: "789795043388",
        appId: "1:789795043388:web:a5d4a2ba198bc5785dab39",
        measurementId: "G-8L24XWRQD1",
      };
      firebase.initializeApp(firebaseConfig);

      const messaging = firebase.messaging();

      messaging.onMessage((payload) => {
        if (this.$route.path == "/list") {
          this.getAlerts();
          this.snackbar = true;
        } else {
          this.$router.push({ path: "/list" });
          this.snackbar = true;
        }
      });

      navigator.serviceWorker.addEventListener("message", (event) => {
        if (event.data.messageType != "notification-clicked") {
          if (this.$route.path == "/list") {
            this.getAlerts();
            this.snackbar = true;
          } else {
            this.$router.push({ path: "/list" });
            this.snackbar = true;
          }
          this.$refs.notif.play();
        } else {
          if (this.$route.path == "/list") {
            this.getAlerts();
            this.snackbar = true;
          } else {
            this.$router.push({ path: "/list" });
            this.snackbar = true;
          }
        }
      });
    }
  },
  methods: {
    ...mapActions([
      "setPlatform",
      "setToken",
      "respondToAlert",
      "getSensors",
      "getAlerts",
    ]),
  },
  watch: {
    snackbar(newValue) {
      if (!newValue) {
        this.$refs.notif.pause();
        this.$refs.notif.currentTime = 0;
      }
    },
  },
};
</script>

<style lang="scss">
.component-wrapper {
  min-height: calc(100vh - 64px - 43px);
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  position: relative;

  @media only screen and (max-width: 959px) {
    min-height: calc(100vh - 56px - 43px);

    &.map {
      height: calc(100vh - 56px - 43px);
    }
  }

  &.map {
    height: calc(100vh - 64px - 43px);

    .vue2leaflet-map {
      height: 100% !important;
    }
  }
}

.footer-logo {
  height: 35px;
  width: 35px;
  object-fit: cover;
}

.leaflet-pane {
  z-index: 1 !important;
}
</style>