<template>
  <div class="component-wrapper align-center pt-8">
    <div class="title mb-10">Σύνδεση</div>

    <form
      @submit.prevent="submit"
      class="form d-flex flex-column"
      novalidate
      autocomplete="off"
    >
      <v-text-field
        label="Email"
        outlined
        v-model="email"
        type="email"
      ></v-text-field>

      <v-text-field
        label="Κωδικός"
        outlined
        v-model="password"
        type="password"
      ></v-text-field>

      <v-btn
        class="align-self-center"
        type="submit"
        :loading="loader"
        :disabled="!$v.email.required || !$v.password.required"
        >Συνδεση</v-btn
      >
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  mixins: [validationMixin],
  data() {
    return {
      email: "",
      password: "",
      loader: false,
    };
  },
  validations: {
    email: { required },
    password: { required },
  },
  methods: {
    ...mapActions(["login"]),
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.loader = true;

      this.login({
        email: this.email,
        password: this.password,
      })
        .then(() => {
          this.loader = false;
          this.$router.push({ path: "/" });
        })
        .catch(() => {
          this.loader = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 400px;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}
</style>