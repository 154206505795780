<template>
  <div class="component-wrapper map">
    <v-btn
      class="mb-2 align-self-start"
      icon
      v-if="alertSensor"
      @click="$router.push({ path: '/list' })"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    <l-map :zoom="zoom" :center="center" :options="mapOptions">
      <l-tile-layer :url="url" :attribution="attribution" />

      <l-marker
        v-for="(sensor, index) in sensorsMapped"
        :key="index"
        :lat-lng="sensor.point"
      >
        <l-icon icon-url="pin.png" :icon-size="[50, 50]" />

        <l-popup>
          <div
            class="popup-wrapper d-flex flex-column align-center justify-center"
          >
            <div class="body-2 font-weight-medium">{{ sensor.id }}</div>

            <div class="d-flex align-center">
              <div class="body-2 font-weight-medium mr-1">Θέση:</div>
              <div class="body-2">{{ sensor.locationDescription }}</div>
            </div>
          </div>
        </l-popup>
      </l-marker>

      <l-marker :lat-lng="accessPoint">
        <l-icon icon-url="gate.svg" :icon-size="[50, 50]" />
        <l-popup>
          <div
            class="popup-wrapper d-flex flex-column align-center justify-center"
          >
            <div class="body-2 font-weight-medium">xaxa</div>

            <div class="d-flex align-center">
              <div class="body-2 font-weight-medium mr-1">Θέση:</div>
              <div class="body-2">xaxa</div>
            </div>
          </div>
        </l-popup>
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LIcon } from "vue2-leaflet";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon,
  },
  computed: {
    ...mapState({
      sensors: (state) => state.user.sensors,
      alertSensor: (state) => state.user.alertSensor,
      sensorsMapped() {
        if (this.alertSensor) {
          return this.sensors
            .filter((s) => s.id == this.alertSensor)
            .map((sensor) => {
              return {
                ...sensor,
                point: latLng(sensor.latitude, sensor.longitude),
              };
            });
        } else {
          return this.sensors.map((sensor) => {
            return {
              ...sensor,
              point: latLng(sensor.latitude, sensor.longitude),
            };
          });
        }
      },
    }),
  },
  data() {
    return {
      zoom: 14,
      center: null,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mapOptions: {
        attributionControl: false,
        zoomControl: false,
      },
      accessPoint: latLng(38.28602077164261, 21.786376833915714),
    };
  },
  created() {
    if (this.alertSensor) {
      const found = this.sensors.find((s) => s.id == this.alertSensor);
      this.center = latLng(found.latitude, found.longitude);
      this.zoom = 17;
    } else {
      this.center = latLng(this.sensors[0].latitude, this.sensors[0].longitude);
    }
  },
  methods: {
    ...mapMutations(["unSetAlertSensor"]),
  },
  destroyed() {
    this.unSetAlertSensor();
  },
};
</script>

<style lang="scss" scoped>
.popup-wrapper {
  position: relative;
}
</style>
