import axios from 'axios';
import firebase from 'firebase/app'
import 'firebase/messaging'
import { Plugins } from "@capacitor/core";

const { PushNotifications, FCMPlugin } = Plugins;

export default {
    state: {
        sensors: [],
        alerts: [],
        alertSensor: null
    },
    mutations: {
        setSensors(state, sensors) {
            state.sensors = sensors;
        },
        setAlerts(state, alerts) {
            state.alerts = alerts;
        },
        setAlertSensor(state, alertSensor) {
            state.alertSensor = alertSensor;
        },
        unSetAlertSensor(state) {
            state.alertSensor = null;
        }
    },
    actions: {
        async registerNotifications({ rootState, dispatch }) {
            if (rootState.platform == 'android') {
                await PushNotifications.register();
                await FCMPlugin.subscribeTo({ topic: process.env.VUE_APP_NOTIFICATION_TOPIC });

                try {
                    await PushNotifications.createChannel({
                        id: 'alerts',
                        importance: 5,
                        name: 'Alert Notifications',
                        sound: 'notif.mp3'
                    });
                } catch (e) {
                    alert(e);
                }

                const fcm = await FCMPlugin.getToken();
                await dispatch('setFcmToken', fcm.token);
            }

            if (rootState.platform == 'web') {
                const messaging = firebase.messaging();

                const fcmToken = await messaging.getToken({ vapidKey: "BDQJ2rQ097yOjlF0kEF0ktoQjN0GVUQviea-GrjsFnU8yDGr1Q5PUkg5ohvG2eYl69ZxGKK-7wUMRVNLYFztSkc" })

                await dispatch('setFcmToken', fcmToken);
            }
        },
        setFcmToken({ rootState }, token) {
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_BASE_URL}/user/setFcmToken`, {
                    token,
                    platform: rootState.platform
                })
                    .then(res => {
                        resolve();
                    })
                    .catch(er => {
                        reject(er);
                    })
            })
        },
        async deleteFcmToken({ rootState }) {
            if (rootState.platform == 'web') {
                const messaging = firebase.messaging();
                const fcmToken = await messaging.getToken({ vapidKey: "BDQJ2rQ097yOjlF0kEF0ktoQjN0GVUQviea-GrjsFnU8yDGr1Q5PUkg5ohvG2eYl69ZxGKK-7wUMRVNLYFztSkc" })
                await axios.post(`${process.env.VUE_APP_BASE_URL}/user/deleteFcmToken`, {
                    token: fcmToken
                })
            }
        },
        getAlerts({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_BASE_URL}/user/getAlerts`)
                    .then(res => {
                        commit("setAlerts", res.data.result);
                        resolve();
                    })
                    .catch(er => {
                        reject(er);
                    })
            })
        },
        respondToAlert({ }, payload) {
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_BASE_URL}/user/action`, {
                    alertId: payload.alertId,
                    type: payload.type
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(er => {
                        reject(er);
                    })
            })
        },
        getSensors({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_BASE_URL}/user/getSensors`)
                    .then(res => {
                        commit('setSensors', res.data.result)
                        resolve();
                    })
                    .catch(er => {
                        reject(er);
                    })
            })
        }
    }
}