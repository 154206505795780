import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth';
import user from './user';
import { Plugins } from "@capacitor/core";

const { Device } = Plugins;

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    platform: null
  },
  mutations: {
    setPlatform(state, platform) {
      state.platform = platform;
    }
  },
  actions: {
    async setPlatform({ commit }) {
      const info = await Device.getInfo();
      commit('setPlatform', info.platform);
    }
  },
  modules: {
    auth,
    user
  }
})
