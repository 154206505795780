import axios from 'axios';
import { Plugins } from '@capacitor/core';
const { Storage, FCMPlugin } = Plugins;
import router from '@/router';

export default {
    state: {
        token: null,
        userId: null,
    },
    getters: {
        isLoggedIn: state => !!state.token
    },
    mutations: {
        login(state, credentials) {
            state.token = credentials.token;
            state.userId = credentials.userId;
        },
        logout(state) {
            state.token = null;
        }
    },
    actions: {
        login({ dispatch }, credentials) {
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_BASE_URL}/auth/login`, {
                    ...credentials
                })
                    .then(res => {
                        axios.defaults.headers.common['Authorization'] = `BEARER ${res.data.token}`;
                        return dispatch("setToken", res.data);
                    })
                    .then(() => {
                        dispatch("getSensors");
                        return dispatch("registerNotifications");
                    })
                    .then(() => {
                        resolve();
                    })
                    .catch(er => {
                        alert(er);
                        dispatch("logout");
                        reject();
                    })
            })
        },
        async setToken({ commit }, credentials) {
            await Storage.set({ key: 'token', value: credentials.token });
            await Storage.set({ key: 'userId', value: credentials.userId });
            axios.defaults.headers.common['Authorization'] = `BEARER ${credentials.token}`;
            commit("login", credentials);
        },
        async logout({ dispatch, rootState }, hasError = false) {
            if (!hasError) {
                try {
                    await dispatch('deleteFcmToken')
                } catch (e) {

                }
            }

            await dispatch("removeToken");

            if (rootState.platform == 'android') {
                FCMPlugin.unsubscribeFrom({ topic: process.env.VUE_APP_NOTIFICATION_TOPIC })
            }

            router.push({ path: "/login" });
        },
        async removeToken({ commit }) {
            delete axios.defaults.headers.common["Authorization"];
            await Storage.remove({ key: 'token' });
            await Storage.remove({ key: 'userId' });
            commit('logout');
        },
    }
}